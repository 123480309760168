import './App.css';
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";


// import { ScrollRestoration } from "react-router-dom";

const Home = lazy(() => import("./Pages/Home"));
const Ourjourney = lazy(() => import("./Pages/OurJourney"));
const Ourmethodology = lazy(() => import("./Pages/Ourmethodology"));
const Ourproject = lazy(() => import("./Pages/Ourproject"));
const Ourpeople = lazy(() => import("./Pages/Ourpeople"));
const Thoughtleadership = lazy(() => import("./Pages/Thoughtleadership"));
const Contactus = lazy(() => import("./Pages/Contactus"));
const Terms = lazy(() => import("./Pages/Terms"));
const Privacy = lazy(() => import("./Pages/Privacy"));

function App() {
  return (
    <>
      <Routes>
        <>
      {/* <ScrollRestoration /> */}
        <Route path="/" element={<LazyLoadingComponents Component={Home} />} />
        <Route
          path="/journey"
          element={<LazyLoadingComponents Component={Ourjourney} />}
        />
        <Route
          path="/methodology"
          element={<LazyLoadingComponents Component={Ourmethodology} />}
        />
        {/* <Route
          path="/project"
          element={<LazyLoadingComponents Component={Ourproject} />}
        /> */}
        {/* <Route
          path="/people"
          element={<LazyLoadingComponents Component={Ourpeople} />}
        /> */}
        <Route
          path="/leadership"
          element={<LazyLoadingComponents Component={Thoughtleadership} />}
        />
        <Route
          path="/contactus"
          element={<LazyLoadingComponents Component={Contactus} />}
        />
         <Route
          path="/terms"
          element={<LazyLoadingComponents Component={Terms} />}
        />
        <Route
          path="/privacy"
          element={<LazyLoadingComponents Component={Privacy} />}
        />
        </>
      </Routes>
    </>
  );
}

export default App;

export function LazyLoadingComponents({ Component }) {
  return (
    <>
      <Suspense fallback={<div></div>}>
        <Component />
      </Suspense>
    </>
  );
}
